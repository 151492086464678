<template>
    <vx-card :title="`Create New Receive`">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Inter Warehouse ID</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="form.sto_code" disabled :readonly="true"
                            class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Source Warehouse</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="`${this.form.src_warehouse.code} - ${this.form.src_warehouse.name}`" disabled
                            :readonly="true" class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Arrival Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <datepicker format="yyyy-MM-dd" name="receive_date" :inline="false" v-model="form.receive_date"
                            placeholder="Select Date" :cleared="() => {
        form.receive_date = null
    }">
                        </datepicker>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>GR Document Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input :value="`auto generated`" disabled :readonly="true"
                            class="w-full bg-grey-light"></vs-input>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Note</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-textarea v-model="form.note" width="100%" />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Attachment</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6 upload">
                        <vs-button v-if="attachment && attachment.length > 0" class="mr-2 up btnx " type="filled"
                            color="primary" icon-pack="feather" icon="icon-plus"
                            @click="$refs.attachment.click()">Upload</vs-button>
                        <vs-button v-else class="mr-2" color="primary" icon-pack="feather" icon="icon-plus"
                            @click="$refs.attachment.click()">Upload</vs-button>
                        <vs-dropdown v-if="attachment && attachment.length > 0">
                            <vs-button class="up btn-drop" type="filled" icon="expand_more"></vs-button>
                            <vs-dropdown-menu>
                                <vs-dropdown-item @click="handleDeleteFile(index)" v-for="(item, index) in attachment"
                                    :key="index">
                                    <vs-icon icon="delete" color="danger"></vs-icon>{{ item.name }}
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                        <input type="file" multiple ref="attachment" accept=".jpg,.jpeg, .png"
                            @change="handleChangeAttachment" style="display: none;" class="invisible appearance-none" />
                        <vs-button class="ml-5" v-if="form.attachment && form.attachment.length > 0"
                            @click="popShow = true" color="primary" type="filled">Download</vs-button>
                        <vs-popup classContent="popup-example" title="Download or delete" :active.sync="popShow">
                            <vs-list>
                                <vs-list-item v-for="(item, index) in form.attachment" :key="index" :title="item.name"
                                    class="flex">
                                    <div class="flex">
                                        <a :href="item.url"><vs-button color="success" type="flat"
                                                icon="file_download"></vs-button></a>
                                        <vs-button color="danger" @click="handleDeleteAttachment(item.id)" type="flat"
                                            icon="delete"></vs-button>
                                    </div>
                                </vs-list-item>
                            </vs-list>
                        </vs-popup>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Shipment Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input v-model="form.shipment_number" class="w-full" />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Delivery Note Number</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-input v-model="form.delivery_note_number" class="w-full" />
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Delivery Note Date</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <datepicker format="yyyy-MM-dd" name="delivery_note_date" :inline="false"
                            v-model="form.delivery_date" placeholder="Select Date" :cleared="() => {
        form.delivery_date = null
    }">
                        </datepicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th colspan="2"></th>
                            <th colspan="2">GR Data</th>
                            <th colspan="2">Conversion</th>
                            <th colspan="4">Batch/ED</th>
                        </tr>
                        <tr>
                            <th width="5%">
                                <div>SKU Code</div>
                            </th>
                            <th width="18%">
                                <div>SKU Description</div>
                            </th>
                            <th width="5%">HU</th>
                            <th width="5%">Qty</th>
                            <th width="5%">HU</th>
                            <th width="5%">Qty</th>
                            <th width="10%">Batch</th>
                            <th width="8%">Expired Date</th>
                            <th width="8%">Check Status</th>
                            <th width="1%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in form.lines_dto" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.relocation_item_unit.sku_code" :readonly="true" disabled
                                    v-if="index == 0 || form.lines_dto[index].stock_relocation_line_id != form.lines_dto[index - 1].stock_relocation_line_id"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.relocation_item_unit.item_name" :readonly="true" disabled
                                    v-if="index == 0 || form.lines_dto[index].stock_relocation_line_id != form.lines_dto[index - 1].stock_relocation_line_id"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.relocation_item_unit.name" :readonly="true" disabled
                                    v-if="index == 0 || form.lines_dto[index].stock_relocation_line_id != form.lines_dto[index - 1].stock_relocation_line_id"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.relocation_quantity" :readonly="true" disabled
                                    v-if="index == 0 || form.lines_dto[index].stock_relocation_line_id != form.lines_dto[index - 1].stock_relocation_line_id"
                                    class="w-full bg-grey-light"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <CustomMSelect :value="item.item_unit"
                                    :base-url="`/api/wms/v1/master/item-unit/related/${item.relocation_item_unit_id}`"
                                    label="name" track-by="name" @selected="(v) => {
        item.item_unit = v;
    }"></CustomMSelect>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input type="number" v-model="item.quantity" class="w-full"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="item.batch" :disabled="item.relocation_item_unit.batch == `None`"
                                    class="w-full"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <datepicker format="yyyy-MM-dd" name="receive_date" class="w-full bg-grey-light"
                                    v-model="item.expired_date" :disabled="item.relocation_item_unit.is_expired == `0`"
                                    placeholder="Select Date" :cleared="() => {
        item.expired_date = null
    }">
                                </datepicker>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <CustomMSelect :value="item.check_status" :options="checkOption" label="name"
                                    track-by="name" @selected="(v) => { item.check_status = v; }">
                                </CustomMSelect>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <div class="flex vx-input-group">
                                    <vs-button @click.stop="removeItemsRow(index)"
                                        v-if="index > 0 && form.lines_dto[index].stock_relocation_line_id == form.lines_dto[index - 1].stock_relocation_line_id"
                                        size="small" color="danger" icon-pack="feather" icon="icon-trash"
                                        style="margin-right: 5px" title="Remove Row" />
                                    <vs-button @click.stop="addItemsRow(index)"
                                        v-if="index == 0 || form.lines_dto[index].stock_relocation_line_id != form.lines_dto[index - 1].stock_relocation_line_id"
                                        size="small" color="success" icon-pack="feather" icon="icon-plus"
                                        title="Add Row" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full vx-col sm:w-1/1">
                <div
                    style="position: absolute; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); ">
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(false)">Save</vs-button>
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(true)">Save and Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>
<script>
import Date from "@/components/Date.vue";
import CustomMSelect from "@/components/CustomMultiSelect.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        Date,
        CustomMSelect,
        vSelect,
        Datepicker,
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        if (this.id) {
            this.initData();
        } else {
            this.$vs.loading();
            setTimeout(this.$vs.loading.close, 3200);
        }
    },
    data() {
        return {
            dialog: {
                save: false,
            },
            id: null,
            popShow: false,
            attachment: null,
            checkOption: [{
                value: 0,
                name: "Good",
            }, {
                value: 1,
                name: "Bad",
            }, {
                value: 2,
                name: "Expired",
            }],
            form: {
                from_warehouse: null,
                receive_date: null,
                gr_document_number: null,
                note: null,
                shipment_number: null,
                delivery_note_number: null,
                delivery_date: null,
                lines_dto: [],
                quantity_received: 0,
            },
        }
    },
    methods: {
        handleSubmit(confirm = false) {
            var data = Object.assign({}, this.form);
            data.stock_relocation_id = Number(this.id);
            try {
                data.lines_dto.forEach(e => {
                    e.quantity = Number(e.quantity)
                    e.status_damage = e.check_status.value
                });
                var formData = new FormData();
                formData.append("data", JSON.stringify(data));
                if (this.attachment) {
                    this.attachment.forEach(item => {
                        formData.append("files", item);
                    });
                }

                this.$vs.loading();
                this.$http
                    .post(`${this.$store.state.inbound.interWarehouse.baseUrlPath}/receive?confirm=${confirm}`, formData)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.form.id = resp.data.id;
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                type: "confirm",
                                color: "success",
                                title: `Data Created`,
                                text: "Created successfully",
                            });
                            this.handleBack();
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                type: "confirm",
                                color: "danger",
                                title: `Error`,
                                text: `${resp.code} : ${resp.message}`,
                            });
                        }
                    });
            } catch (error) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Error`,
                    text: `${error}`,
                });
            }
        },
        handleChangeAttachment(event) {
            this.attachment = event.target.files ? event.target.files : null;
        },
        handleDeleteFile(index) {
            var files = Array.from(this.attachment)
            files.splice(index, 1)
            this.attachment = files;
        },
        handleDeleteAttachment(id) {
            this.$vs.loading();
            this.$http.delete(`${this.$store.state.inbound.interWarehouse.baseUrlPath}/receive/relocation-receive/${this.form.id}/attachment/${id}`)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.form.attachment = resp.data;
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                });
        },
        addItemsRow(index) {
            var count = 0;
            this.form.lines_dto.forEach((item) => {
                if (this.form.lines_dto[index].stock_relocation_line_id == item.stock_relocation_line_id) count++;
            })
            if (count < 3) {
                const newItem = Object.assign({}, this.form.lines_dto[index])
                newItem.id = 0;
                this.form.lines_dto.splice(index + 1, 0, newItem);
            }
        },
        removeItemsRow(index) {
            if (this.form.lines_dto[index].stock_relocation_line_id == this.form.lines_dto[index - 1].stock_relocation_line_id) {
                this.form.lines_dto.splice(index, 1)
            }
        },

        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.inbound.interWarehouse.baseRouterName,
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.interWarehouse.baseUrlPath}/receive/relocation/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.form = resp.data;
                        if (resp.data.delivery_date.match("0001-01-01")) {
                            this.form.delivery_date = moment(resp.data.relocation_date).add(1, "d").toDate();
                        }
                        if (resp.data.receive_date.match("0001-01-01")) {
                            this.form.receive_date = moment().toDate();
                        }
                        if (resp.data.sto_code == "") {
                            this.form.sto_code = resp.data.relocation_code
                        }
                        if (resp.data.note == "") {
                            this.form.note = resp.data.relocation_note;
                        }
                        this.form.lines_dto.forEach(e => {
                            if (e.item_unit_id == 0) {
                                e.item_unit = e.relocation_item_unit;
                            }
                            if (e.quantity == 0) {
                                e.quantity = e.relocation_quantity;
                            }
                            if (e.expired_date.match("0001-01-01")) {
                                e.expired_date = null;
                            }
                            e.check_status = this.checkOption[Number(e.status_damage)];
                        });
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    }
}
</script>
<style>
.vs-input--input:disabled,
input:disabled {
    pointer-events: auto !important;
}

.upload {
    display: flex;
}

.upload button.up {
    margin: 0px !important;
}

.upload button.btnx {
    margin-left: 10px !important;
    border-radius: 5px 0px 0px 5px;
}

.upload button.btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
}
</style>